import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  appConfig: string;
  // keyPassword: string;
  // imagesServerPath:string
  constructor(private http: HttpClient) { }
  loadAppConfig() {
    return this.http.get("assets/config.json").toPromise().then((data: any) => {
      this.appConfig = data.apiUrl;
      // this.keyPassword = data.keyPassword;
      // this.imagesServerPath=data.imagesServerPath;
    })
  }

}
