import { Component } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { ArtworkClient, UploadAssetsVM } from 'src/Model/AppModel';
import { ConfigService } from '../services/config.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class HomeComponent {
  excelFile = null;
  zipFile = null;
  artWorkClient: ArtworkClient;
  constructor(private http: HttpClient,
    private client: ArtworkClient,
    private configservice: ConfigService,
    private modalService: NgbModal,
    config: NgbModalConfig) {
    this.artWorkClient = client;
    config.backdrop = 'static';
    config.keyboard = false;

  }
  ngOnInit() {

  }

  uploadingZipFile(event) {

    this.zipFile = <File>event.target.files[0];
    document.getElementById('zipFileLabel').innerText = this.zipFile.name;
    if (!this.zipFile.name.includes('.zip')) {
      this.zipFile = null;
      document.getElementById('zipFileValidation').hidden = false;
    } else {
      document.getElementById('zipFileValidation').hidden = true;
    }

    // console.log(this.zipFile)
  }
  uploadingExcelFile(event) {
    this.excelFile = <File>event.target.files[0];
    document.getElementById('excelFileLabel').innerText = this.excelFile.name;
    if (!(this.excelFile.name.includes('.xls') || this.excelFile.name.includes('.xlsx'))) {
      this.excelFile = null;
      document.getElementById('excelFileValidation').hidden = false;
    } else {
      document.getElementById('excelFileValidation').hidden = true;
    }


    // console.log(this.excelFile)
  }

  startUploadAssets() {
    document.getElementById('failResultDiv').hidden = true;
    document.getElementById('successResultDiv').hidden = true;

    if (this.zipFile != null ) {
      document.getElementById('startUploadAssets').innerHTML = 'Loading';
      (<HTMLButtonElement>document.getElementById('startUploadAssets')).disabled = true;
      this.artWorkClient.uploadAssets(this.zipFile)
        .subscribe(el => this.updatingUI(el, null),
          err => this.updatingUI(null, err),
          () => console.log('complete')
        );

    }
  }

  updatingUI(success: UploadAssetsVM, error) {
    document.getElementById('startUploadAssets').innerHTML = 'Submit';
    (<HTMLButtonElement>document.getElementById('startUploadAssets')).disabled = false;
    if (success) {
      if (success.statusCode !== 200) {
        document.getElementById('successResultDiv').hidden = true;
        document.getElementById('failResultDiv').hidden = false;
        document.getElementById('failResultDiv').innerHTML = '';
        success.errors.forEach(error => {
          document.getElementById('failResultDiv').innerHTML += error.errorDescription + '\n';
        });
      } else {

        document.getElementById('successResultDiv').hidden = false;
        document.getElementById('failResultDiv').hidden = true;

      }
    } else {
      document.getElementById('failResultDiv').hidden = false;
      document.getElementById('failResultDiv').innerHTML = error.message;


    }
  }
}
