import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ArtworkClient } from '../Model/AppModel';
import { ConfigService } from './services/config.service';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    FetchDataComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgbModalModule,
    RouterModule.forRoot([
      { path: 'uploadAssets', component: HomeComponent, pathMatch: 'full' },
      { path: '', component: FetchDataComponent },
    ])
  ],
  providers: [
    ArtworkClient,
    ConfigService,
    HttpClientModule
    // {
    //   provide: APP_INITIALIZER,
    //   multi: true,
    //   deps: [ConfigService],
    //   useFactory: (configService: ConfigService) => {
    //     return () => {
    //       return configService.loadAppConfig();
    //     };
    //   },
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
