import { Component } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { ArtworkClient, CheckMatchesVM, UploadAssetsVM } from 'src/Model/AppModel';
import { ConfigService } from '../services/config.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './fetch-data.component.html',
  styleUrls: ['./fetch-data.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class FetchDataComponent {
  excelFile = null;
  userEmail = '';
  constructor(private http: HttpClient,
    private artWorkClient: ArtworkClient,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;

  }
  ngOnInit() {

  }
  uploadingExcelFile(event) {
    this.excelFile = <File>event.target.files[0];
    document.getElementById('excelFileLabel').innerText = this.excelFile.name;
    if (!(this.excelFile.name.includes('.xls') || this.excelFile.name.includes('.xlsx'))) {
      this.excelFile = null;
      document.getElementById('excelFileValidation').hidden = false;
    } else {
      document.getElementById('excelFileValidation').hidden = true;
    }


    // console.log(this.excelFile)
  }

  checkMatches() {
    document.getElementById('failResultDiv').hidden = true;
    document.getElementById('successResultDiv').hidden = true;

    if (this.excelFile != null && this.userEmail !== '') {
      document.getElementById('checkMatches').innerHTML = 'Loading';
      (<HTMLButtonElement>document.getElementById('checkMatches')).disabled = true;
      this.artWorkClient.checkMatches(this.excelFile, this.userEmail + '@takwene.com')
        .subscribe(el => this.updatingUI(el, null),
          err => this.updatingUI(null, err),
          () => console.log('complete')
        );

    }
  }

  updatingUI(success: CheckMatchesVM, error) {
    document.getElementById('checkMatches').innerHTML = 'Submit';
    (<HTMLButtonElement>document.getElementById('checkMatches')).disabled = false;
    if (success) {
      if (success.statusCode !== 200) {
        document.getElementById('failResultDiv').hidden = false;
        document.getElementById('successResultDiv').hidden = true;
        document.getElementById('failResultDiv').innerHTML = '';
        success.errors.forEach(error => {
          document.getElementById('failResultDiv').innerHTML += error.errorDescription + '\n';
        });
      } else {

        document.getElementById('successResultDiv').hidden = false;
        document.getElementById('failResultDiv').hidden = true;
        // document.getElementById('downloadLink').setAttribute('href', success.downloadLink);
      }
    } else {
      document.getElementById('failResultDiv').hidden = false;
      document.getElementById('failResultDiv').innerHTML = error.message;


    }
  }
}
